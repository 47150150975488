import AOS from "aos";

if (typeof pmt == "undefined") {
  var pmt = {};
}

/* Globals */
var $window = $(window),
  mobile_nav_break = 1024,
  header_top = 0,
  pmt_sec = $("#pmt-sec").attr("data-pmt");

/*-----------------------
@COMMON
------------------------*/
pmt.common = (function() {
  return {
    init: function() {
      this.dummy_links();
    },

    anchor_links: function() {
      $('a[href^="#"]')
        .not(
          ".nav-section-link, .skip-link, .question-submit-btn, .quiz-contact-submit-btn, .quiz-submit-btn, .close-btn, .video-trigger"
        )
        .click(function(e) {
          e.preventDefault();

          var selected_container = $(this).attr("href"),
            $root = $("html, body");

          $root.animate(
            {
              scrollTop:
                $(selected_container).offset().top - $("header").outerHeight()
            },
            500,
            function() {
              //window.location.hash = selected_container;
            }
          );
        });
    },

    simulate_focus_events: function(e) {
      document.addEventListener(
        "keyup",
        function(e) {
          if (e.keyCode == 9) {
            if ($(".card-link-wrapper:focus").length) {
              var $this = $(".card-link-wrapper:focus"),
                $parent = $this.parent(".column");
              $parent.addClass("has-focused-link");
              $(".column")
                .not($parent)
                .removeClass("has-focused-link");
            } else {
              $(".column").removeClass("has-focused-link");
            }
          }
        },
        false
      );

      document.addEventListener("click", function(e) {
        if ($(".has-focused-link").length) {
          $(".column").removeClass("has-focused-link");
        }
      });
    },

    dummy_links: function() {
      $(document).on("click", "a[href='#']", function(e) {
        e.preventDefault();
      });
    },

    get_pixel_ratio: function() {
      var ctx = document.createElement("canvas").getContext("2d"),
        dpr = window.devicePixelRatio || 1,
        bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1;

      return dpr / bsr;
    },

    // http://stackoverflow.com/questions/17907445/how-to-detect-ie11#comment30165888_17907562
    is_ie: function() {
      return (
        navigator.appName == "Microsoft Internet Explorer" ||
        (navigator.appName == "Netscape" &&
          new RegExp("Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})").exec(
            navigator.userAgent
          ) !== null)
      );
    },

    is_ipad: function() {
      var is_ipad = navigator.userAgent.match(/iPad/i) !== null;
      return is_ipad;
    },

    is_mobile_device: function() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPod/i)
      ) {
        return true;
      }
      return false;
    },

    validate_email: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    window_resize: function(func, ms) {
      var doit;

      window.addEventListener("resize", function() {
        clearTimeout(doit);
        doit = setTimeout(function() {
          pmt.resize_events.init();
        }, ms);
      });

      /*
            window.onresize = function() {
                clearTimeout(doit);
                doit = setTimeout(func, ms);
            };
            */
    }
  };
})();

pmt.main = (function() {
  return {
    init: function() {
      this.register_events();
      this.calculate_padding();

      $window.scroll(pmt.scroll_functions.scroll_handler);
      pmt.scroll_functions.scroll_handler();

      AOS.init({
        once: true,
        duration: 500,
        easing: "ease-in-sine",
        startEvent: "load"
      });
    },

    register_events: function() {
      pmt.common.anchor_links();
      pmt.common.simulate_focus_events();
      pmt.nav.init();
      pmt.tabs.init();
      pmt.contact_forms.init();
      pmt.modals.init();
      pmt.blog.init();
      pmt.events.init();
      pmt.alert_bar.init();
      pmt.event_tracking.init();
      pmt.ipn.init();
      pmt.faqs.init();
      pmt.carousels.init();
      pmt.search.init();
      pmt.benefit_cards.init();
    },

    calculate_padding: function() {
      var main_padding = $("header").outerHeight();
      $("main").css({ "margin-top": main_padding + "px" });
    }
  };
})();

pmt.nav = (function() {
  return {
    init: function() {
      this.mobile_toggle();
      this.section_links();
    },

    mobile_toggle: function() {
      $(".mobile-toggle").on({
        click: function(e) {
          var $this = $(this);

          if ($("html").hasClass("mobile-nav-active")) {
            $this.removeClass("mobile-nav-open");
            $("html").removeClass("mobile-nav-active");
          } else {
            $("html").addClass("mobile-nav-active");
            $this.addClass("mobile-nav-open");
          }
        }
      });
    },

    section_links: function(e) {
      $(".nav-section-link").on({
        click: function(e) {
          e.preventDefault();
          var $this = $(this),
            target = $this.attr("data-target");

          if ($this.hasClass("active")) {
            $this.removeClass("active");
            $(".nav-dropdown-background").removeClass("active");
            $(target)
              .removeClass("nav-dropdown-active")
              .attr("aria-hidden", true);
            $("html").removeClass("mega-menu-active");
          } else {
            $("html").addClass("mega-menu-active");
            $this.addClass("active");
            $(".nav-section-link")
              .not($this)
              .removeClass("active");

            $(".nav-dropdown")
              .not($(target))
              .removeClass("nav-dropdown-active");
            $(target)
              .addClass("nav-dropdown-active")
              .attr("aria-hidden", false);

            if (
              !$(".nav-dropdown-background").hasClass("nav-dropdown-active")
            ) {
              $(".nav-dropdown-background").addClass("active");
            }

            pmt.nav.resize_backdrop();
          }
        }
      });

      $(document).on("click", ".mega-menu-backdrop", function(e) {
        pmt.nav.hide_mega_menu();
      });
    },

    resize_backdrop: function() {
      $(".nav-dropdown-background").css({
        height: $(".nav-dropdown-active").outerHeight() + "px"
      });
    },

    hide_mega_menu: function() {
      $(".nav-dropdown-background").removeClass("active");
      $("html").removeClass("mega-menu-active");
      $(".nav-section-link.active").removeClass("active");
      $(".nav-dropdown-active")
        .removeClass("nav-dropdown-active")
        .attr("aria-hidden", true);
    }
  };
})();

pmt.scroll_functions = (function() {
  return {
    scroll_handler: function() {}
  };
})();

pmt.tabs = (function() {
  return {
    init: function() {
      pmt.tabs.set_tab_container_height();

      $(".tab").on({
        click: function(e) {
          e.preventDefault();
          var $this = $(this),
            target = $this.attr("data-tab"),
            tab_group = $this.attr("data-tab-group");

          $this
            .addClass("active")
            .attr("aria-selected", "true")
            .focus();
          $(".tab")
            .not($this)
            .removeClass("active")
            .attr("aria-selected", "false");

          $(target).addClass("active");
          $(tab_group + " > .tab-content")
            .not($(target))
            .removeClass("active");
          pmt.tabs.set_tab_container_height();
        }
      });
    },

    set_tab_container_height: function() {
      if ($(".in-page-nav").length) {
        $(".in-page-nav").each(function() {
          var tab_container_height = $(this)
            .find(".tab-content.active")
            .outerHeight();
          $(this)
            .find(".tabs-row")
            .css({ "min-height": tab_container_height + "px" });
        });
      }
    }
  };
})();

pmt.contact_forms = (function() {
  return {
    init: function() {
      $(".form-select").on({
        click: function(e) {
          var $this = $(this),
            $label = $this.parent(),
            $target = $($this.attr("data-target"));

          $(".form-select-label")
            .not($label)
            .removeClass("selected");
          $label.addClass("selected");
          $target.addClass("active");
          $(".contact-form-container")
            .not($target)
            .removeClass("active");
        }
      });

      $("[data-controls]").on({
        change: function() {
          var $this = $(this),
            target = $this.attr("data-controls"),
            toggle_value = $this.attr("data-control-value");

          if ($this.val() == toggle_value) {
            $('[data-form-group="' + target + '"]').removeClass(
              "conditional-hidden"
            );
          } else {
            $('[data-form-group="' + target + '"]').addClass(
              "conditional-hidden"
            );

            // Clear checkboxes
            $('[data-form-group="' + target + '"] input:checkbox').each(
              function() {
                $(this).prop("checked", false);
              }
            );
          }
        }
      });

      $(".custom-form-submit-btn").on({
        click: function(e) {
          e.preventDefault();

          var complete_submission = false,
            error_count = 0,
            form_id = $(this).attr("data-submits-form"),
            $form = $("#" + form_id),
            form_id_number = $form.attr("data-form-number"),
            pardot_url = $("#pardot_url_" + form_id_number).val(),
            referral_url = $("#referral_url_" + form_id_number).val(),
            process_url = "https://clientfloor.com/paymentus/forms/api.php",
            $response_container = $(
              "#custom-form-response-container-" + form_id_number
            ),
            has_checkboxes = false,
            checkbox_values = "",
            $validation_container = $("#form-status-" + form_id_number);

          var form_fields = document.getElementById(form_id).elements,
            field_data = {};

          field_data["action"] = "add-contact";
          field_data["pardot_url"] = pardot_url;

          for (var i = 1; i < form_fields.length - 1; i++) {
            var this_id = form_fields[i].id,
              field_type = form_fields[i].dataset.type,
              required = form_fields[i].dataset.required,
              $field = $("#" + this_id);

            if (required == "true") {
              if (field_type == "email") {
                if (pmt.common.validate_email($field.val())) {
                  $field.removeClass("error");
                  $field
                    .parent()
                    .find(".required")
                    .removeClass("active");
                } else {
                  error_count++;
                  $field.addClass("error");
                  $field
                    .parent()
                    .find(".required")
                    .addClass("active");
                }
              } else {
                if ($field.val() != "") {
                  $field.removeClass("error");
                  $field
                    .parent()
                    .find(".required")
                    .removeClass("active");
                } else {
                  error_count++;
                  $field.addClass("error");
                  $field
                    .parent()
                    .find(".required")
                    .addClass("active");
                }
              }
            }
          }

          if ($('[data-type="checkbox"]').length) {
            var $parent_label = $('[data-type="checkbox')
              .first()
              .parent()
              .parent();

            if ($('[data-type="checkbox"]:checked').length) {
              $parent_label.find(".required").removeClass("active");
              has_checkboxes = true;

              $('[data-type="checkbox"]:checked').each(function() {
                checkbox_values +=
                  checkbox_values != "" ? ";" + $(this).val() : $(this).val();
              });
            } else {
              $parent_label.find(".required").addClass("active");
            }
          }

          //console.log(form_fields);
          //console.log(checkbox_values);

          if (error_count == 0) {
            complete_submission = true;
            $('[data-submits-form="custom-form-' + form_id_number + '"]').attr(
              "disabled",
              true
            );
          }

          if (complete_submission) {
            for (var i = 1; i < form_fields.length - 1; i++) {
              if (form_fields[i].type != "checkbox") {
                field_data[form_fields[i].name] = form_fields[i].value;
              }
            }

            if (has_checkboxes) {
              var checkbox_field = $('[data-type="checkbox')
                .first()
                .attr("name");
              checkbox_field = checkbox_field.replace("[]", "");
              field_data[checkbox_field] = checkbox_values;
            }

            //console.log(field_data);

            field_data["token"] = pmt_sec;

            $.get(
              process_url,
              field_data,
              function(response) {
                //console.log(response);
                var response_array = $.parseJSON(response);

                if (response_array.redirect_url != "") {
                  location.href = response_array.redirect_url;
                  $validation_container
                    .removeClass("error success active-message")
                    .html("");
                } else {
                  $validation_container.html(response_array.message);
                }

                if (response_array.message_type == "success") {
                  $validation_container
                    .removeClass("error")
                    .addClass("success active-message");
                } else {
                  $validation_container
                    .removeClass("success")
                    .addClass("error active-message");
                }

                $("html, body").animate(
                  {
                    scrollTop:
                      $validation_container.offset().top -
                      $("header").outerHeight()
                  },
                  500
                );

                // Reset fields
                $(
                  '[data-submits-form="custom-form-' + form_id_number + '"]'
                ).attr("disabled", false);
                for (var i = 1; i < form_fields.length - 1; i++) {
                  form_fields[i].value = "";
                }

                $("#custom-form-" + form_id_number + " input:checkbox").each(
                  function() {
                    $(this).prop("checked", false);
                  }
                );

                $("#referral_url_" + form_id_number).val(referral_url);
              },
              "html"
            );
          } else {
            var validation_message = $validation_container.attr(
              "data-default-message"
            );
            $validation_container
              .addClass("active-message error")
              .html("<p>" + validation_message + "</p>");
            $("html, body").animate(
              {
                scrollTop:
                  $validation_container.offset().top - $("header").outerHeight()
              },
              500
            );
          }
        }
      });
    }
  };
})();

pmt.modals = (function() {
  return {
    init: function() {
      $(document).on("click", ".modal-trigger", function(e) {
        e.preventDefault();
        var modal_id = $(this).attr("data-modal-id");
        pmt.modals.trigger_modal(modal_id);
      });

      $(document).on("click", ".video-trigger", function(e) {
        e.preventDefault();
        var modal_id = $(this).attr("data-modal-id"),
          video_title = $(this).attr("data-video-title"),
          video_content = $(this).attr("data-video-content");
        pmt.modals.trigger_video_modal(modal_id, video_title, video_content);
      });
    },

    trigger_modal: function(modal_id) {
      var element = document.getElementById(modal_id),
        dialog = new A11yDialog(element);

      dialog
        .on("show", function(element, event) {
          $("html").addClass("modal-open");
        })
        .on("hide", function(element, event) {
          $("html").removeClass("modal-open");
        });

      dialog.show();
    },

    trigger_video_modal: function(modal_id, video_title, video_content) {
      var element = document.getElementById(modal_id),
        dialog = new A11yDialog(element);

      dialog
        .on("show", function(element, event) {
          $("html").addClass("modal-open");
        })
        .on("hide", function(element, event) {
          $("html").removeClass("modal-open");
          $("#modal-content").html("");
        });

      var modal_video_content = $("#" + video_content).html();

      var formatted_modal_video_content = modal_video_content.replace(
        "data-src=",
        "src="
      );
      $("#modal-title").html(video_title);
      $("#modal-content").html(formatted_modal_video_content);

      dialog.show();
    }
  };
})();

pmt.blog = (function() {
  return {
    init: function() {
      $("#blog-filter").on({
        change: function() {
          var select_value = $(this).val();

          if (select_value != "") {
            $("#blog-grid-results").load(select_value + " #blog-grid-content");
          }
        }
      });

      $(document).on("click", "#blog-view-more-btn", function(e) {
        e.preventDefault();
        var show_page = $(this).attr("data-show-page"),
          next_page = parseInt(show_page) + 1,
          total_pages = parseInt($(this).attr("data-total-pages"));
        $('[data-page="' + show_page + '"]').show();
        $(this).attr("data-show-page", next_page);

        if (next_page > total_pages) {
          $(this).hide();
        }
      });
    }
  };
})();

pmt.events = (function() {
  return {
    init: function() {
      pmt.events.tabs();
      pmt.events.anchor_links();
    },

    anchor_links: function() {
      $("#virtual-learning-cards a").on({
        click: function(e) {
          e.preventDefault();
          var active_events = $(this).attr("href"),
            active_tab = $('[data-target="' + active_events + '"]');

          $(active_tab).addClass("active");
          $(active_events).addClass("active");

          $(".series-container")
            .not($(active_events))
            .removeClass("active");
          $(".event-tab")
            .not($(active_tab))
            .removeClass("active");

          pmt.events.scroll_to_container(active_events);
        }
      });
    },

    tabs: function() {
      $(".event-tab").on({
        click: function(e) {
          e.preventDefault();
          var $this = $(this),
            target = $(this).attr("data-target");

          $(target).addClass("active");
          $this.addClass("active");
          $(".series-container")
            .not($(target))
            .removeClass("active");
          $(".event-tab")
            .not($this)
            .removeClass("active");
        }
      });
    },

    scroll_to_container: function(container) {
      $("html, body").animate(
        {
          scrollTop: $(container).offset().top - $("header").outerHeight()
        },
        500
      );
    }
  };
})();

pmt.event_tracking = (function() {
  return {
    init: function() {
      if (window.dataLayer) {
        $(".event-tracker").on({
          click: function(e) {
            var label = $(this).attr("data-event-label"),
              category = $(this).attr("data-event-category"),
              action = $(this).attr("data-event-action");
            ga("gtm2.send", {
              hitType: "event",
              eventCategory: category,
              eventAction: action,
              eventLabel: label
            });
          }
        });
      }
    }
  };
})();

pmt.alert_bar = (function() {
  return {
    init: function() {
      $("[data-alert-dismiss]").on({
        click: function(e) {
          e.preventDefault();
          var alert = $(this).attr("data-alert-dismiss");
          pmt.cookies.set_cookie("alert-bar-status", "dismissed");
          $(alert).remove();
        }
      });
    }
  };
})();

pmt.cookies = (function() {
  return {
    set_cookie: function(cookie_name, cookie_value, expires) {
      var secure = window.dataLayer ? " Secure;" : "";
      var options = "path=/; SameSite=Lax;" + secure;

      document.cookie = cookie_name + "=" + cookie_value + "; " + options;
    }
  };
})();

pmt.ipn = (function() {
  return {
    init: function() {
      $(".section-link[data-description]").on({
        click: function(e) {
          e.preventDefault();
          var $this = $(this),
            description = $this.attr("data-description"),
            active_nodes = $this.attr("data-active"),
            $active_nodes = $(active_nodes);

          if ($(description).hasClass("active")) {
            $(".description").removeClass("active"); // remove all
            $(".ipn-graphic-container g").removeClass("dim");
            $this.removeClass("active");
          } else {
            $this.addClass("active");
            $(description).addClass("active");
            $(".description")
              .not($(description))
              .removeClass("active");
            $(".section-link")
              .not($this)
              .removeClass("active");
            $(
              '.ipn-graphic-container g[id^="logos-"], .ipn-graphic-container g[id^="nodes-"], .ipn-graphic-container g[id^="lineB-"], .ipn-graphic-container g[id^="lineA-"]'
            )
              .not($active_nodes)
              .addClass("dim");
            $active_nodes.removeClass("dim");
          }
        }
      });
    }
  };
})();

pmt.faqs = (function() {
  return {
    init: function() {
      if (location.hash) {
        var initial_hash = location.hash,
          faq_hash = initial_hash.replace("#", "");

        if ($('[data-answer-target="' + faq_hash + '"]').length) {
          $('[data-answer-target="' + faq_hash + '"]').addClass("active");
          $('[data-answer="' + faq_hash + '"]').addClass("active");

          $("html, body").animate(
            {
              scrollTop:
                $('[data-answer-target="' + faq_hash + '"]').offset().top -
                $("header").outerHeight()
            },
            500,
            function() {}
          );
        }
      }

      $(".question").on({
        click: function(e) {
          e.preventDefault();
          var $this = $(this),
            answer_target = $this.attr("data-answer-target");
          $this.toggleClass("active");
          $('[data-answer="' + answer_target + '"]').toggleClass("active");
        }
      });
    }
  };
})();

pmt.carousels = (function() {
  return {
    init: function() {
      var $carousel = $(".slick");

      $carousel.each(function() {
        var $this = $(this),
          this_id = $this.attr("id");

        $(this).slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          useTransform: false,
          speed: 500,
          infinite: false
          //adaptiveHeight: true
        });

        $this.on("setPosition", function() {
          //pmt.carousels.slick_equal_height(this_id);
        });
      });
    },

    slick_equal_height: function(this_id) {
      var $carousel = $("#" + this_id);

      $carousel.each(function() {
        $carousel.find(".slick-item").height("auto");

        var slick_track = $carousel.find(".slick-track");
        var slick_track_height = $(slick_track).height();

        $carousel.find(".slick-item").css("height", slick_track_height + "px");

        /*
                setTimeout(function() {
                    if(!$carousel.hasClass('slick-content-active')) {
                        $carousel.addClass('slick-content-active');
                    }
                }, 500);
                */
      });
    }
  };
})();

pmt.search = (function() {
  return {
    init: function() {
      $(".search-icon").on({
        click: function(e) {
          e.preventDefault();
          $(this).toggleClass("search-icon-active");
          $(".dropdown-nav-search-container").toggleClass(
            "dropdown-search-active"
          );
        }
      });
    }
  };
})();

pmt.benefit_cards = (function() {
  return {
    init: function() {
      var card_resize;
      pmt.benefit_cards.set_height();

      $(".benefit-card").on({
        click: function(e) {
          if (window.innerWidth <= 1024) {
            e.preventDefault();
            $(this).toggleClass("active");
          }
        },

        mouseenter: function() {
          if (window.innerWidth > 1024) {
            $(this).addClass("active");
          }
        },

        mouseleave: function() {
          if (window.innerWidth > 1024) {
            $(this).removeClass("active");
          }
        }
      });

      window.addEventListener("resize", function() {
        clearTimeout(card_resize);
        card_resize = setTimeout(function() {
          pmt.benefit_cards.set_height();
        }, 100);
      });
    },

    set_height: function() {
      var height = 0;

      $(".benefit-card").each(function() {
        var card_front_height = $(this)
            .find(".card-front")
            .height(),
          card_back_height = $(this)
            .find(".card-back")
            .height();

        if (card_front_height > card_back_height) {
          if (card_front_height > height) {
            height = card_front_height;
          }
        } else {
          if (card_back_height > height) {
            height = card_back_height;
          }
        }
      });

      height = height + 60;

      $(".card-back").addClass("hidden");
      $(".benefit-card").css({ height: height + "px" });
    }
  };
})();

pmt.resize_events = (function() {
  return {
    init: function() {
      pmt.main.calculate_padding();
      pmt.scroll_functions.scroll_handler();

      if ($(".nav-dropdown-active").length) {
        pmt.nav.resize_backdrop();
      }

      if (window.innerWidth >= mobile_nav_break) {
        $("html").removeClass("mobile-nav-active");
      }
    }
  };
})();

$(window).on("load", function() {
  AOS.refresh();

  if (location.hash) {
    //window.scrollTo(0,0);
    var hash = location.hash.split("#"),
      $target = $("#" + hash.slice(1));

    if ($target.length && $target != "form-gate") {
      window.scrollTo(0, $target.offset().top - $("header").outerHeight());
    }

    /*
        setTimeout(function() {
            var hash = location.hash.split('#'),
            $target = $('#' + hash.slice(1));
            $('html, body').animate({
                scrollTop: $target.offset().top - $('header').outerHeight()
            },1000);

        }, 500);
        */
  }
  pmt.common.window_resize("pmt.resize_events.init()", 100);
});

jQuery(function() {
  pmt.main.init();
});
